import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getOrders(pagination) {
    return api.get(
      "retailers/orders",
      { params: pagination },
      { headers: authHeader() }
    );
  },
  getCheckoutLink(ordersId) {
    return api.post(
      "retailers/orders/checkout",
      {
        order_line_ids: ordersId,
      },
      { headers: authHeader() }
    );
  },
  
  payOrder(order,orders_line_ids) {
    return api.post(
      "retailers/orders/cj/pay/" + order.orderNum,
      {
        order_line_ids: orders_line_ids,
      },
      { headers: authHeader() }
    );
  },
  
};
