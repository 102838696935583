<template>
  <main>
    <div>
      <retailerNavigation v-if="!$route.path.includes('/sh-retailer')" />

      <v-main class="retailer-main-container-checkout"
        :class="{ 'retailer-main-container-checkout-not-shopify': !isShopify }" app>
        <div>
          <div class="query_container mb-5">
            <h1 class="mb-0" style="font-weight: bold; font-size: 20px">
              Order management
            </h1>
          </div>
          <div v-if="isLoading && !userProfile.payment_method" class="commission-base-section mb-5">
            <div class="commission-container">
              <h4 class="title">
                You didn’t provide your payment information yet!
              </h4>
              <p class="desc">
                To process your orders, please provide your payment information.
                We collect commission only after your customers received their
                orders.
              </p>
              <PButton @click="completeStripeInfo()" class="checkout-btn" primary>
                Complete payment info
              </PButton>
              <!-- <span @click="upgradePlanDialog = !upgradePlanDialog" class="Upgrade-link">
                Upgrade to Pro - 0% Commission
              </span> -->
            </div>
          </div>
          <div class="checkout-page">
            <div class="checkout-advance_search d-flex justify-space-between">
              <div class="advance_search-search-container d-flex gap-4">
                <SearchBtnV3 class="search-input" placeholder="Filter" @search="Search($event)"
                  @removeText="removeText()" />
                <BaseSelectDrop class="order-status hidden-on-mobile" :placeholder="'Order status'" :options="orderStatus"
                  :selectedOption="selectedStatus" @selectOption="SortByObject($event, 'status')" />
              </div>

              <div class="sort-container">
                <BaseSelectDrop class="order-status hidden-on-web" :placeholder="'Order status'" :options="orderStatus"
                  :selectedOption="selectedStatus" @selectOption="SortByObject($event, 'status')" />

                <BaseSelectDropSort class="Sort-type" :placeholder="'Sort'" :options="sortList"
                  :selectedOption="selectedSort" @selectOption="SortByObject($event, 'sort')" />
              </div>
            </div>

            <div class="chips my-4">
              <div v-if="searchedTitle || selectedStatus || selectedSort" @click="clearAllFilter()"
                class="chipsContainer__clearall">
                Clear all
              </div>
              <div v-if="searchedTitle" class="chips__item">
                <div class="chips__item__text">{{ searchedTitle }}</div>
                <button @click="removeSearchedTitle()" type="button"
                  class="v-icon notranslate chips__item__icon v-icon--link mdi mdi-close theme--light material-symbols-outlined">
                  close
                </button>
              </div>

              <div v-if="selectedStatus" class="chips__item">
                <div class="chips__item__text">{{ selectedStatus.value }}</div>
                <button @click="removeFilteredStatus()" type="button"
                  class="v-icon notranslate chips__item__icon v-icon--link mdi mdi-close theme--light material-symbols-outlined">
                  close
                </button>
              </div>
              <div v-if="selectedSort" class="chips__item">
                <div class="chips__item__text">{{ selectedSort.value }}</div>
                <button @click="removeFilteredSort(selectedSort)" type="button"
                  class="v-icon notranslate chips__item__icon v-icon--link mdi mdi-close theme--light material-symbols-outlined">
                  close
                </button>
              </div>
            </div>
            <v-row>
              <v-col cols="12" sm="12" md="12" v-if="ordersCopy.length > 0">
                <PCard class="mb-5" v-for="order in paginatedProducts" :key="order.id" sectioned="">
                  <!-- <span @click="expandModal($event)" class="icon open-icon material-symbols-outlined">
                    expand_more
                  </span>
                  <span @click="expandModal($event)" class="icon close-icon material-symbols-outlined">
                    keyboard_arrow_up
                  </span> -->

                  <div>
                    <PStackItem class="step-guide-header">
                      <h5 class="title">
                        Order Number: <span>{{ order.external_id }}</span>
                      </h5>
                      <span class="status" style="padding-left: 0;">
                        {{ order.customer?.name }} /
                        {{ order.customer?.email }}
                      </span>

                      <div class="desc d-flex align-items-center">
                        <p @click="handleCustomerInfoModal(order.customer)" class="blue-customerInfo">
                          Customer Info
                        </p>
                        <p class="px-1">|</p>
                        <a aria-label=" Report an issue" target="_blank" :href="'mailto:info@vgang.io?subject=' + order.external_id
                          " class="blue-customerInfo text-decoration-underline Report-an-issue">
                          Report an issue
                        </a>
                      </div>
                    </PStackItem>
                    <PStackItem class="steps-section">
                      <PCollapsible id="basic-collapsible" expandOnPrint :open="true" :transition="{
                        duration: '500ms',
                        timingFunction: 'ease-in-out',
                      }">
                        <div class="px-4">
                          <div class="Polaris-IndexTable">
                            <div>
                              <div class="Polaris-IndexTable-ScrollContainer">
                                <table class="Polaris-IndexTable__Table Polaris-IndexTable__Table--sticky">
                                  <thead>
                                    <tr class="border-bottom-tr">
                                      <th
                                        class="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--second pl-4"
                                        data-index-table-heading="true">
                                        Image
                                      </th>
                                      <th class="Polaris-IndexTable__TableHeading" data-index-table-heading="true">
                                        Item
                                      </th>
                                      <th class="Polaris-IndexTable__TableHeading" data-index-table-heading="true">
                                        Quantity
                                      </th>
                                      <th
                                        class="Polaris-IndexTable__TableHeading Polaris-IndexTable--tableHeadingAlignEnd"
                                        data-index-table-heading="true">
                                        Cost
                                      </th>
                                      <th class="Polaris-IndexTable__TableHeading" data-index-table-heading="true">
                                        Supplier
                                      </th>
                                      <th class="Polaris-IndexTable__TableHeading" data-index-table-heading="true">
                                        Order Status
                                      </th>
                                      <th
                                        class="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--last text-right"
                                        data-index-table-heading="true">
                                        Selling price
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="(
                                      seller, sellerIndex
                                    ) in order.sellers" :key="seller.id" class="position-relative">
                                    <tr v-for="(line, lineIndex) in seller.lines" :key="line.id" :class="{
                                      'border-bottom-tr':
                                        lineIndex + 1 ==
                                        seller.lines.length &&
                                        sellerIndex + 1 !==
                                        order.sellers.length,
                                    }">
                                      <td class="Polaris-IndexTable__TableCell">
                                        <img width="40px" height="40px" loading="lazy" :src="line.image"
                                          :alt="line.title" />
                                      </td>
                                      <td class="Polaris-IndexTable__TableCell">
                                        {{ line.title | truncate(25, "...") }}
                                      </td>
                                      <td class="Polaris-IndexTable__TableCell">
                                        {{ line.quantity }}
                                      </td>
                                      <td class="Polaris-IndexTable__TableCell">
                                        {{ toCurrency(line.price) }}
                                      </td>
                                      <router-link :to="'./supplierProfile/' +
                                        seller.seller_id
                                        ">
                                        <td class="Polaris-IndexTable__TableCell">
                                          <div class="supplier-customerInfo">
                                            {{
                                              seller.seller_name
                                              | truncate(18, "...")
                                            }}
                                          </div>
                                        </td>
                                      </router-link>
                                      <td class="Polaris-IndexTable__TableCell">
                                        <div class="status-header d-flex align-items-center">
                                          <div style="text-align: left">
                                            <span v-if="line.financial_status === '0'" class="status waiting">
                                              Waiting For Payment
                                            </span>
                                            <span v-else-if="line.financial_status === '1' || line.financial_status === 'PaidPartially'
                                              " class="status pending">
                                              In progress
                                            </span>
                                            <span v-else-if="line.financial_status === '2'" class="status paid">
                                              Fulfilled
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="Polaris-IndexTable__TableCell">
                                        <PButton v-if="lineIndex <= 0" @click="
                                          setLoadingKey(order?.external_id + '-' + sellerIndex);
                                          getCheckout(order.sellers[sellerIndex]);
                                        " class="checkout-btn" primary>
                                          <div class="loading-wrapper-small"
                                            v-if="loadingKey === (order?.external_id + '-' + sellerIndex)">
                                            <div class="lds-ellipsis">
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                            </div>
                                          </div>
                                          <span v-else>Checkout</span>
                                        </PButton>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div
                            class="Polaris-IndexTable__ScrollBarContainer Polaris-IndexTable--scrollBarContainerHidden">
                            <div class="Polaris-IndexTable__ScrollBar" style="
                                --pc-index-table-scroll-bar-content-width: 828px;
                              ">
                              <div class="Polaris-IndexTable__ScrollBarContent"></div>
                            </div>
                          </div>
                        </div>
                      </PCollapsible>
                    </PStackItem>
                  </div>
                </PCard>
              </v-col>
              <PCard v-else class="empty-products-container mt-3">
                <div class="container row-gap-3 d-flex justify-content-center flex-column align-items-center">
                  <img class="chair-image" src="@/assets/img/importListBg.png" loading="lazy"
                    alt="import list is empty" />
                  <h4 class="header">Your order list is empty!</h4>
                  <p class="description">
                    You can manage and process your orders here. Head over to
                    the find product page to start adding products to your shop.
                  </p>
                  <router-link :to="'./list-of-products?host=' + host">
                    <PButton class="Explore-btn" primary>
                      Explore Products to Sell
                    </PButton>
                  </router-link>
                </div>
              </PCard>
            </v-row>
            <div v-if="totalPages > 1" class="row">
              <div class="col-sm-12 col-md-12 col-12" style="display: flex; justify-content: center">
                <nav role="navigation" aria-label="Pagination Navigation" style="width: auto; display: flex">
                  <ul class="v-pagination theme--light">
                    <li>
                      <button :disabled="currentPage === 1" type="button" aria-label="Previous page"
                        class="v-pagination__navigation v-pagination__navigation--disabled"
                        @click="changePage(currentPage - 1)">
                        <span class="material-symbols-outlined v-icon notranslate mdi mdi-chevron-left theme--light">
                          chevron_left
                        </span>
                      </button>
                    </li>

                    <li v-for="page in totalPages" :key="page">
                      <button :type="currentPage === page ? 'button' : 'button'" :aria-label="currentPage === page
                        ? 'Current Page, Page ' + page
                        : 'Goto Page ' + page
                        " :class="[
    'v-pagination__item',
    {
      'v-pagination__item--active': currentPage === page,
    },
  ]" @click="changePage(page)" :style="currentPage === page
  ? {
    'background-color': 'rgb(0, 143, 74)',
    'border-color': 'rgb(0, 143, 74)',
    color: '#fff',
  }
  : {}
  " class="v-pagination__item">
                        {{ page }}
                      </button>
                    </li>

                    <li>
                      <button :disabled="currentPage === totalPages" type="button" aria-label="Next page"
                        class="v-pagination__navigation" @click="changePage(currentPage + 1)">
                        <span class="v-icon notranslate mdi mdi-chevron-right theme--light material-symbols-outlined">
                          chevron_right
                        </span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <v-dialog v-model="customerInfoModal" max-width="400px">
          <v-card>
            <div class="card_header d-flex align-center justify-space-between px-6 py-2"
              style="background-color: #f5f6f7">
              <h2 style="font-weight: bold">Customer More Info</h2>

              <BaseBtn class="vgang_btn btn_green" @clicked="customerInfoModal = false" :icon="'close'" />
            </div>
            <div class="py-6 d-flex justify-center align-center">
              <table>
                <tr>
                  <td style="font-weight: bold">Name:</td>
                  <td style="padding-left: 10px">{{ customerName }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Email:</td>
                  <td style="padding-left: 10px">{{ cusotmerEmail }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">City:</td>
                  <td style="padding-left: 10px">{{ customerCity }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Address:</td>
                  <td style="padding-left: 10px">{{ customerAddress }}</td>
                </tr>
              </table>
            </div>
          </v-card>
        </v-dialog>

        <PModal :open="approvePriceModal" sectioned :primaryAction="{
          content: 'Checkout',
          onAction: payCj,
        }" title="Payment" @close="toggleApprovePriceModal">
          <PFormLayout>
            <div class="d-flex align-center justify-space-between my-3">
              <h2 class="text_header" style="font-weight: bold;">Subtotal</h2>
              <h2 class="text_header" style="font-weight: bold;">
                ${{ this.approveOrder?.order_data?.productAmount }} ({{ this.approveOrder?.order_data?.productList?.length
                }}
                item)
              </h2>
            </div>
            <div class="d-flex align-center justify-space-between my-3">
              <h2 class="text_header" style="font-weight: bold;">Shipping</h2>
              <h2 class="text_header" style="font-weight: bold;">
                ${{ this.approveOrder?.order_data?.postageAmount }}
              </h2>
            </div>

            <div class="linehr"></div>
            <div class="d-flex align-center justify-space-between my-3">
              <h2 class="text_header" style="font-weight: bold;">Total</h2>
              <h2 class="text_header" style="font-weight: bold;">
                ${{ this.approveOrder?.order_data?.orderAmount }}
              </h2>
            </div>

          </PFormLayout>
        </PModal>


        <PModal :open="paymentModal" sectioned :primaryAction="{
          content: 'Yes',
          onAction: paymentFulfilled,
        }" :secondaryActions="[{ content: 'No', onAction: toggleModal }]" title="Payment Message" @close="toggleModal">
          <PFormLayout>
            <h3>Have you fulfilled your payment?</h3>
          </PFormLayout>
        </PModal>
        <PModal :open="isActiveCommissionModal" @close="toggleCommissionModal" sectioned :primaryAction="{
          content: 'Complete payment info',
          onAction: paymentFulfilled,
        }" title="Payment Message">
          <PFormLayout>
            <h4 style="line-height: 26px">
              To process your orders, you need to provide your payment
              information first. We collect commission only after your customers
              received their orders.
            </h4>
          </PFormLayout>
        </PModal>
      </v-main>
      <!-- <PlanSubUpgradeModal v-if="Object.keys(currentUserAccountInformation).length &&
        currentUserAccountInformation.nextPlan.order &&
        upgradePlanDialog
        " :showButton="false" :headerBtn="true" :plan="currentUserAccountInformation.nextPlan"
        :key="currentUserAccountInformation.nextPlan.order" :upgradePlanDialog="upgradePlanDialog"
        @closeUpgradePlanDialog="upgradePlanDialog = false" @planUpgraded="getAccountInfo()">
      </PlanSubUpgradeModal> -->
    </div>
  </main>
</template>
<script>
// components
import BaseBtn from "@/components/bases/BaseBtn.vue";
import retailerNavigation from "@/components/navigation/retailer.vue";
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
// Services
import CommonData from "@/services/CommonData";
import PlanSubUpgradeModal from "@/components/retailer/modals/planSubUpgradeModal.vue";
import RetailerOrders from "@/services/RetailerOrders";
import { setVariable } from "@/utils/conditionalStoreVariables";
import SearchBtnV3 from "@/components/bases/SearchBtnV3.vue";
import BaseSelectDrop from "@/components/bases/BaseSelectDrop.vue";
import BaseSelectDropSort from "@/components/bases/BaseSelectDropSort.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Checkout",
  components: {
    retailerNavigation,
    BaseBtn,
    SearchBtnV3,
    BaseSelectDrop,
    BaseSelectDropSort,
    PlanSubUpgradeModal,
  },
  data() {
    return {
      isShopify: false,
      userType: "retailer",
      orders: [],
      ordersCopy: [],
      order_lines: [],
      open: true,
      isLoading: false,
      paymentModal: false,
      approvePriceModal: false,
      loadingKey: -1,
      approveOrder: {},
      host: null,
      isActiveCommissionModal: false,
      customerInfoModal: false,
      customerName: "",
      customerCity: "",
      cusotmerEmail: "",
      customerAddress: "",
      redirectURL: "",
      defaultImage: "@/assets/img/no-image.png",
      searchedTitle: undefined,
      selectedStatus: undefined,
      selectedSort: undefined,
      itemsPerPage: 24,
      currentPage: 1,
      upgradePlanDialog: false,
      userProfile: {},
      stripeLink: null,
      orderStatus: [
        { id: "Created", value: "Waiting for payment" },
        { id: "PaidPartially", value: "In progress" },
        { id: "Fulfilled", value: "Shipped" },
        { id: "Canceled", value: "Canceled" },
      ],
      sortList: [
        { id: "Newest", value: "Newest" },
        { id: "oldest", value: "Oldest" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUserAccountInformation: "retailer/currentUserAccountInformation",
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
    }),
    defaultCurrency() {
      return this.orders[0].sellers[0].lines[0].currency
        ? this.orders[0].lines[0].currency
        : "";
    },
    totalPages() {
      // Calculate the total number of pages based on the number of filtered products
      return Math.ceil(this.ordersCopy.length / this.itemsPerPage);
    },
    paginatedProducts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage * this.itemsPerPage;
      return this.ordersCopy.slice(startIndex, endIndex);
    },
  },
  filters: {
    truncate: function (text, length, clamp) {
      return text?.length > length ? text.slice(0, length) + clamp : text;
    },
  },
  async created() {
    try {
      if (isShopifyEmbedded()) {
        this.isShopify = true;

        const url_string = window.location.href;
        const url = new URL(url_string);
        this.host = url.searchParams.get("host");
      }
      await this.getUserProfile();
      await this.getOrders();
      await this["retailer/getCurrentUserAccountInformation"](false);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions([
      "alert/clear",
      "alert/newAlert",
      "retailer/getCurrentUserAccountInformation",
    ]),
    changePage(page) {
      this.currentPage = page;
    },
    getUserProfile() {
      CommonData.getCurrentUser()
        .then((response) => {
          this.userType = response?.data?.role;
          this.userProfile = response.data;
          this.isLoading = true;
          if (this.userType) {
            setVariable("role", this.userType);
          }
        })
        .catch((e) => {
          console.log(e);
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    forwardToStripeLink() {
      CommonData.getStripeLink()
        .then((response) => {
          this.stripeLink = response.data.link.url;
          if (this.stripeLink == "") {
            this["alert/newAlert"]({
              type: "error",
              message: "Oops! Something went wrong. Please try again later. ",
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          } else {
            window.open(
              this.stripeLink,
              "_blank" // <- This is what makes it open in a new window.
            );
          }

          if (this.isActiveCommissionModal)
            this.isActiveCommissionModal = false;
          window.addEventListener("focus", () => this.getUserProfile());
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    completeStripeInfo() {
      if (!this.userProfile.payment_method) {
        this.forwardToStripeLink();
      }
    },
    removeText() {
      this.removeSearchedTitle();
    },
    toggleUpgradeModal() {
      this.toggleCommissionModal();
      this.upgradePlanDialog = !this.upgradePlanDialog;
    },
    async Search(event) {
      let filteredOrder = [];
      for (const order of this.orders) {
        let found = false;

        for (const seller of order.sellers) {
          for (const line of seller.lines) {
            if (line.title.includes(event.trim())) {
              filteredOrder.push(order);
              found = true;
              break; // Stop searching within this order once a match is found
            }
          }
        }
      }

      this.ordersCopy = filteredOrder;
      this.searchedTitle = event;
    },
    removeSearchedTitle() {
      this.ordersCopy = this.orders;
      this.searchedTitle = undefined;
      if (this.selectedStatus) {
        this.SortByObject(this.selectedStatus, "status");
      }
      if (this.selectedSort) {
        this.SortByObject(this.selectedSort, "sort");
      }
    },
    removeFilteredStatus() {
      this.ordersCopy = this.orders;
      this.selectedStatus = undefined;
      if (this.searchedTitle) {
        this.Search(this.searchedTitle);
      }

      if (this.selectedSort) {
        this.SortByObject(this.selectedSort, "sort");
      }
    },
    removeFilteredSort() {
      this.SortByObject({ id: "Newest", value: "Newest" }, "sort");
      this.selectedSort = undefined;

      if (this.searchedTitle) {
        this.Search(this.searchedTitle);
      }

      if (this.selectedStatus) {
        this.SortByObject(this.selectedStatus, "status");
      }
    },
    clearAllFilter() {
      this.searchedTitle = undefined;
      this.selectedStatus = undefined;
      this.selectedSort = undefined;
      this.ordersCopy = this.orders;
    },
    async SortByObject(event, type) {
      if (type === "status") {
        this.selectedStatus = event;
        let filteredOrders = [];
        let newOrders = this.orders;

        this.ordersCopy = newOrders.map((order) => {
          if (order?.financial_status == this.selectedStatus.id) {
            filteredOrders.push(order);
          }
        });

        this.ordersCopy = filteredOrders;
      }
      if (type === "sort") {
        this.selectedSort = event;

        let filteredOrder = [];
        if (event.value === "Oldest") {
          filteredOrder = this.ordersCopy.sort((a, b) => {
            const dateA = new Date(a.sellers[0].lines[0].created_at);
            const dateB = new Date(b.sellers[0].lines[0].created_at);

            return dateA - dateB;
          });

          this.ordersCopy = filteredOrder;
        }

        if (event.value === "Newest") {
          let filteredOrder = [];

          filteredOrder = this.ordersCopy.sort((a, b) => {
            const dateA = new Date(a.sellers[0].lines[0].created_at);
            const dateB = new Date(b.sellers[0].lines[0].created_at);

            return dateB - dateA;
          });
          this.ordersCopy = filteredOrder;
        }
      }
    },
    expandModal(event) {
      event.target.parentNode.classList.toggle("collapse-close");
    },
    getOrders() {
      RetailerOrders.getOrders()
        .then((response) => {
          this.orders = response.data.orders;
          this.ordersCopy = this.orders;
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    toCurrency: function (price) {
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: this.defaultCurrency ? this.defaultCurrency : "USD",
        //currency: "EUR",
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;
    },
    paymentFulfilled() {
      this.forwardToStripeLink();
    },
    toggleModal() {
      this.paymentModal = !this.paymentModal;
    },

    toggleApprovePriceModal() {
      if(this.approvePriceModal){
        this.setLoadingKey(-1)
      }
      this.approvePriceModal = !this.approvePriceModal;
    
    },
    setLines(order_lines) {
      this.order_lines = order_lines;
    },

    setOrder(order) {
      this.approveOrder = order
    },
    payCj() {
      RetailerOrders.payOrder(this.approveOrder?.order_data, this.order_lines)
        .then((response) => {
          console.log(response);
          this.toggleApprovePriceModal();
          window.location.reload();
        })
        .catch((e) => {
          this.toggleApprovePriceModal();
          this.$store.dispatch(
            "alert/newAlert",
            { type: "error", message: e.data },
            {
              root: true,
            }
          );
          setTimeout(async () => {
            this["alert/clear"]();
          }, 3000);
        });
    },

    toggleCommissionModal() {
      this.isActiveCommissionModal = !this.isActiveCommissionModal;
    },
    setLoadingKey(key) {
      console.log(key)
                                    
      this.loadingKey = key;
    },
    getCheckout(orderSellers) {
      if (!this.userProfile.payment_method) {
        this.toggleCommissionModal();
        return;
      }
      const ordersId = orderSellers.lines.map((line) => {
        return line.id.toString();
      });

      RetailerOrders.getCheckoutLink(ordersId)
        .then((response) => {
          console.log(response);
          this.setLines(ordersId)
          if (response?.data?.redirectUrl) {
            this.redirectURL = response.data.redirectUrl;
            setTimeout(() => {
              this.toggleModal();
            }, 1000);
            window.open(this.redirectURL, "_blank");
          } else if (response?.data?.order_data?.orderNum) {
            this.setOrder(response?.data)
            this.toggleApprovePriceModal();

          }
        })
        .catch((e) => {
          console.log(e);
          this.setLoadingKey(-1)

          this.$store.dispatch(
            "alert/newAlert",
            { type: "error", message: e.data.message },
            {
              root: true,
            }
          );
          setTimeout(async () => {
            this["alert/clear"]();
          }, 3000);
        });
    },

    handleCustomerInfoModal(customerInfo) {
      this.customerName = customerInfo.name;
      this.customerCity = customerInfo.city;
      this.cusotmerEmail = customerInfo.email;
      this.customerAddress = customerInfo.address;
      this.customerInfoModal = !this.customerInfoModal;
    },
  },
};
</script>

<style lang="scss">
.retailer-main-container-checkout {
  .commission-base-section {
    background: #fff5ea;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e1b878;

    .commission-container {
      .title {
        color: #202223;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .desc {
        color: #202223;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .Upgrade-link {
        margin-left: 14px;
        cursor: pointer;
        color: #2c6ecb;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        text-decoration-line: underline;
      }
    }
  }

  .border-bottom-tr {
    border-bottom: 1px solid #e1e3e5;
  }

  .empty-products-container {
    width: 98%;
    margin: 0 auto;
    padding: 89px;

    @include md {
      padding: 34px;
    }

    @include sm {
      padding: 21px;
    }

    .chair-image {
      height: 172px;
      width: 148px;
    }

    .Explore-btn {
      font-size: 12px;
    }

    .header {
      color: #202223;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      border-bottom: unset !important;

      @include sm {
        font-size: 16px;
      }
    }

    .description {
      color: #6d7175;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .chipsContainer__clearall {
    color: #008f4a;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    margin-right: 20px;
    font-size: 16px;
  }

  .chips {
    display: flex;
    align-items: center;

    &__item {
      display: flex;
      align-items: center;
      background-color: #e4e7ec;
      border-radius: 4px;
      padding: 4px 6px;
      margin-right: 10px;

      &__text {
        margin-right: 20px;
        padding: 0;
        font-weight: 600;
        color: black;
        font-size: 14px;

        &--lessImportant {
          font-weight: lighter;
        }
      }

      &__icon {
        font-weight: 600;
        color: black;
        font-size: 1.7rem;
        cursor: pointer;
      }
    }
  }

  .checkout-advance_search {
    @include md {
      flex-direction: column;
      row-gap: 10px;
    }

    .sort-container {
      .baseSelect__label__input {
        width: 100% !important;
        font-size: 12px !important;
        margin-left: 20px !important;
      }

      @include md {
        display: flex;
        column-gap: 10px;
      }
    }

    .search-input {
      width: 40vw;
      background: #fff;

      @include md {
        width: 95vw;
      }
    }

    .order-status {
      width: 160px;

      .baseSelect__label {
        padding: 11px 14px;
      }

      .baseSelect__label__input::placeholder {
        font-size: 12px;
        color: #202223;
      }

      .baseSelect__label__input {
        font-size: 12px;
      }
    }

    .Sort-type {
      width: 120px;

      .baseSelect__label__input::placeholder {
        font-size: 14px;
        color: #202223;
      }
    }
  }

  .step-guide-header {
    border-bottom: unset !important;
  }

  .upgrade-card {
    background: #f1f8f5;
    border: 1px solid #b3d0c3;

    @include md {
      flex-direction: column;
      row-gap: 16px;

      .Polaris-Heading {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .steps-section {
    padding: 0 !important;
    padding-top: 0 !important;
    margin: 0 !important;
  }

  .collapse-close {
    .step-guide-header {
      border-bottom: 1px solid #d9d9d9 !important;
    }

    #basic-collapsible {
      max-height: none !important;
      overflow: unset !important;
      visibility: visible;
      display: block;
    }

    .open-icon {
      display: none;
    }

    .close-icon {
      display: flex !important;
    }

    .steps-section {
      padding: 14px 0 !important;
    }
  }

  .Polaris-IndexTable__TableHeading {
    font-size: 14px;
  }

  .Polaris-Card__Section {
    position: relative;
    padding-bottom: 0 !important;
  }

  .close-icon {
    display: none !important;
  }

  .icon {
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    top: 14px;
    right: 8px;
    text-align: right;

    border-radius: 7px;
    cursor: pointer;
    z-index: 12;

    transition: all 0.1s ease-in-out;

    &:hover {
      background: rgb(219, 219, 219);
    }
  }

  .status {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 8px !important;
    flex-direction: row;
    align-items: center;

    width: fit-content;
    margin-top: 0;
    margin-right: 35px;
    margin-bottom: 8px;

    .status-icon {
      vertical-align: text-bottom;
      margin-right: 5px;
      display: inline-block;
      font-size: 14px;
    }

    &.waiting {
      color: #202223;
      background: #ffd79d;
    }

    &.delivered-to-post {
      color: white;
      background: #0ac2ff;
      border-radius: 5px;
    }

    &.pending {
      color: #012f44;
      background: #b8e7fc;
    }

    &.paid {
      color: #05603a;
      background: #cde9ce;
    }

    &.cancel {
      color: #1d2939;
      background: #e4e7ec;
      margin-right: 8px;
    }

    &.refund {
      color: #ffffff;
      background: #344054;
    }
  }

  .supplier-customerInfo {
    padding: 11px 0;
  }

  .supplier-customerInfo,
  .blue-customerInfo {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2c6ecb !important;
    cursor: pointer;
    text-decoration: underline;
  }

  .blue-customerInfo {
    color: #2c6ecb !important;
  }

  .Report-an-issue {
    margin-bottom: 16px;
  }

  .Polaris-IndexTable__TableCell {
    font-size: 14px;
    color: #202223;

    img {
      border-radius: 5px;
    }

    .checkout-btn {
      padding: 8px 14px;
      position: absolute;
      right: 1%;
      bottom: 50%;
      transform: translateY(50%);

      min-height: unset;

      .Polaris-Button__Text {
        font-size: 12px;
      }
    }
  }

  .status-header {}

  .section-header {
    color: #202223;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .step-guide-header {
    border-bottom: 1px solid #d9d9d9;
    padding-left: 25px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 14px;

    .title {
      color: #202223;
      font-size: 16px !important;
      cursor: pointer;
      font-weight: 700;
      line-height: 24px;
    }

    .desc {
      color: #202223;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .Polaris-Card__Section {
    padding-left: 0;
    padding-right: 0;
    transition: all 0.3s ease-in;
  }

  .no-access {
    border-radius: 10px;
    text-align: center;
    color: #ff0a43;
    padding: 30px;
    border: 1px solid #ff0a43;
  }

  .page-wrapperch {
    background: #ffffff;
    min-height: 93vh;
    padding: 24px;
    padding-left: 96px;
    max-width: 1700px;
  }

  .importlist_banner {
    margin-top: 16px;

    width: 100%;
  }

  .checkout-page {
    .status {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      padding: 5px 8px;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;
      width: fit-content;
      margin-top: 8px;

      .status-icon {
        vertical-align: text-bottom;
        margin-right: 5px;
        display: inline-block;
        font-size: 18px;
      }

      &.waiting {
        color: #661712;
        background: #fcebe9;
        border-radius: 4px;
      }

      &.delivered-to-post {
        color: white;
        background: #0ac2ff;
        border-radius: 5px;
      }

      &.pending {
        color: #012f44;
        background: #b8e7fc;
      }

      &.paid {
        color: #05603a;
        background: #cde9ce;
      }

      &.cancel {
        color: #1d2939;
        background: #e4e7ec;
        margin-right: 8px;
      }

      &.refund {
        color: #ffffff;
        background: #344054;
      }
    }

    .each-order {
      background: white;
      border-radius: 10px;
      margin-bottom: 16px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 0px 2px rgba(16, 24, 40, 0.06);

      .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 45px;
        padding-bottom: 7px;
        padding-top: 16px;
        padding-left: 24px;
        border-bottom: 1px dashed #d7dce3;

        .order-number,
        .order-status {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #101828;
        }

        .customer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 16px;
          margin-right: -24px;
          margin-top: 7px;
        }

        .order-customerInfo {
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #008f4a;
          cursor: pointer;

          margin-left: 27px;
          text-decoration: underline;
        }

        .infoImage {
          margin-right: 7px;
        }

        .each-row {
          border-top: 1px solid #ededed;
        }
      }

      .order-body {
        overflow-x: scroll;

        .orderDetail-table {
          width: 100%;
          margin-top: 25px;

          .header {
            th {
              padding: 15px;
              font-size: 14px;
              font-weight: 700;
              font-family: "Open Sans";
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: normal;
              color: #1d2939;
              text-transform: uppercase;
              border-bottom: 1px solid #ededed;

              &:first-child {
                padding-left: 24px;
                width: 400px;
              }
            }
          }

          .body {
            .each-row {
              height: 24px;
              border-top: 1px solid #ededed;

              td {
                padding: 16px;

                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #435160;
              }

              .table {
                padding-right: 33px;
              }

              .sellerpro {
                border-top: 1px solid #ffffff;
                padding: 20px;
                padding-left: 1px;
              }

              .order-image-wrapper {
                display: flex;
                align-items: center;
                width: 240px;

                img {
                  width: 64px;
                  border-radius: 5px;
                  box-shadow: 0 20px 50px 10px rgba(29, 41, 54, 0.1);
                  margin-right: 14px;
                }

                span {
                  font-size: 14px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.43;
                  letter-spacing: normal;
                  color: #435160;
                }
              }
            }
          }
        }
      }
    }

    .product-page-modal-body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .linehr {
    width: 100%;
    height: 1px;
    border-bottom: 2px solid black;
  }

  .empty-space {
    position: relative;

    ._title {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #435160;
      margin-bottom: 30px;
    }

    ._desc {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #435160;
      margin-bottom: 30px;
    }

    ._bg {
      position: absolute;
      top: 100px;
      right: 100px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .btn_disabled1 {
    color: #d0d5dd;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    cursor: pointer;
    margin-right: 27px;
    margin-left: 7px;
  }
}

.v-pagination {
  display: flex;
}

.v-pagination li {
  list-style: none;
  width: 30px;
  height: 30px;

  margin: 2px;
  justify-content: center;
  align-items: center;
}

.v-pagination li button {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #babfc3;
}

.v-pagination li span {
  display: block;

  text-align: center;
}

.tableEdtiBtn {
  cursor: pointer;
  border: none !important;
  padding: 5px !important;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.pagination button {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0073e6;
  color: white;
  border-color: #0073e6;
}

.retailer-main-container-checkout-not-shopify {
  margin-top: 20px;

  .query_container {
    margin-top: 20px;
  }

  .empty-space {
    padding-left: 60px !important;

    ._title {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 1621px) {
    width: calc(100% - 284px) !important;
    margin-left: auto;
  }

  @media screen and (min-width: 300px) {
    width: calc(100% - 105px);
    margin-left: auto;
    margin-right: 15px;
  }
}
</style>
